import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router';
import Events from './components/pages/Events';
import Gallery from './components/pages/Gallery';
import Story from './components/pages/Story';
import Travel from './components/pages/Travel';
import Home from './components/pages/Home';
import Faq from './components/pages/Faq';
//import ProtectedRoutes from './ProtectedRoutes';
//import LoginPage from './components/pages/LoginPage';
import { createContext, useState } from "react";
//import LogOut from './components/pages/LogOut';
import ScrollToTop from './components/ScrollTop';

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({ /*loggedIn: false*/ });
  return (
    <UserContext.Provider value={{ user, setUser }}>
    <div className='App'>
      <Navbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*<Route element={<ProtectedRoutes />}> */}
          <Route path="/home" element={<Home />} />
          <Route path="/eventos" element={<Events />} />
          <Route path="/fotos" element={<Gallery />} />
          <Route path="/historia" element={<Story />} />
          <Route path="/viajeros" element={<Travel />} />
          <Route path="/faq" element={<Faq />} />
          {/*<Route path="/logOut" element={<LogOut />} /> */}
        {/*</Route>*/}
        <Route path="*" element={<Home/>} />
</Routes>
        <div>
          <p style={{ fontSize: '12px' }}>
            Arte de fondo por{' '}
            <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
            <a href="https://instagram.com/liliweds.co?igshid=MzRlODBiNWFlZA==" style={{ textDecoration: 'none' }} target="_blank">liliweds.co</a>.
            <br />
            &copy; Derechos de autor Liliweds 2023 - Todos los derechos reservados.
          </p>
          {/* Copyright and Picture Disclaimer */}
          <p style={{ fontSize: '12px' }}>
            El arte y fotografías en este sitio web no pueden ser utilizadas ni reproducidas sin autorización.
          </p>
        </div>
    </div>
    </UserContext.Provider>
  );
}

export default App;
