import React from 'react';
import '../../App.css';
import Countdown from "react-countdown";
import { Typography, Box, CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme1, theme2, theme3 } from '../Theme.js';
import ImageListItem from "@mui/material/ImageListItem";
import { CircleLoader } from "react-spinners";

export default function Home() {

    const [loading, setLoading] = React.useState(true);
    const handleImageLoad = () => {
      setLoading(false);
    };
    // Random component
    const Completionist = () => <Typography display="block" align="center">¡Gracias por haber formado parte de nuestro gran día! </Typography>
    const targetDate = new Date('December 29, 2023');
    const currentDate = new Date();

    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const targetDay = targetDate.getDate();
    const targetMonth = targetDate.getMonth();
    const targetYear = targetDate.getFullYear();

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (currentDay === targetDay && currentMonth === targetMonth && currentYear === targetYear)
        {
            // Render a complete state
            return <Typography display="block" align="center" fontSize={34}> ¡Llegó el gran día! </Typography>
        }
        else if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            // Render a completion message for the target date
            return <Completionist />;
        }
        else if (days === 0)
        {
          return <Typography display="block" align="center" fontSize={34}> ¡Falta menos de un día! </Typography>
        }
        else {
        // Render a countdown
            return (
                <Typography display="block" align="center" fontSize={34}>
                ¡Faltan {days + 1} días!
                </Typography>
            );
        }
    };

    return (
        <React.Fragment>
        <ThemeProvider theme={theme3}>
            <CssBaseline />
            <Box>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>

            <Typography display="block" align="center" fontSize={80}>Nuestra Boda</Typography>
            <Typography> &nbsp; </Typography>
            <div>
            <ThemeProvider theme={theme2}>
            <CssBaseline/>
            <Typography display="block" align="center" fontSize={34}>
                Geraldo y Kiara
            </Typography>

            <Typography display="block" align="center" fontSize={34}>viernes, 29 de diciembre del 2023 | San Juan, PR</Typography>
            <Countdown date={targetDate} renderer={renderer} />
            </ThemeProvider>
            </div>
            <Typography> &nbsp; </Typography>   
            </Box>
        </ThemeProvider>

        <ThemeProvider theme={theme1}>
        <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          marginBottom: '20px', // Add margin to create space at the bottom,
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          [theme1.breakpoints.up('mobile')]: {
            width: 300,
          },
          [theme1.breakpoints.up('bigMobile')]: {
            width: 355,
          },
          [theme1.breakpoints.up('table')]: {
            width: 600,
          },
          [theme1.breakpoints.up('desktop')]: {
            width: 900
          }
        }}
      >
          <Box>
          <ImageListItem
           cols={1}
           rows={1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'cursor',
              '&:hover': {opacity:1}
           }}
           
           >
          <img src = 'https://gkwedding2923images-g7g5ejccg0eraedu.z01.azurefd.net/images/photo12.jpg' alt="home" loading="lazy" preload="auto"
                  onLoad={handleImageLoad} style={{ opacity: loading ? 0 : 1 }}/>
          {loading && (
                  <div className="spinner-container">
                    <CircleLoader color="#CB8A36" loading size={100}   />
                  </div>
          )}
          </ImageListItem>
        </Box>
      </Box>
      <div>
          <p style={{ fontSize: '12px' }}>
            Fotografía por{' '}
            <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
            <a href="https://instagram.com/jilliansuttenphotography?igshid=MzRlODBiNWFlZA==" style={{ textDecoration: 'none' }} target="_blank">JillianSuttenPhotography</a>.
          </p>
          {/* Copyright and Picture Disclaimer */}
          <p style={{ fontSize: '12px' }}>
            &copy; Derechos de autor JillianSuttenPhotography 2023 - Todos los derechos reservados.
          </p>
      </div>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>

    </ThemeProvider>

        </React.Fragment>
    );
}