import React from 'react';
import '../../App.css';
import { Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { theme1, theme3, theme2  } from '../Theme.js';
import ImageListItem from "@mui/material/ImageListItem";
import { CircleLoader } from "react-spinners";

export default function Events() {
  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div>
    <React.Fragment>
    <ThemeProvider theme={theme3}>
    <CssBaseline />
    <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography display="block" align="center" fontSize={50}>Eventos</Typography>
        <Typography> &nbsp; </Typography>
        <div>
          <ThemeProvider theme={theme2}>
          <CssBaseline/>
          <Typography display="block" align="center" fontSize={25} variant="caption">Acompáñanos el viernes, 29 de diciembre del 2023.</Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography display="block" align="center" fontSize={25}>Ceremonia de boda</Typography>
          <Typography display="block" align="center">La ceremonia será celebrada a las cuatro en punto de la tarde
          <br/>
           en los jardines del Antiguo Casino de Puerto Rico.</Typography>

      <Typography> &nbsp; </Typography>

      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography display="block" align="center" fontSize={25}>Recepción</Typography>

      <Typography display="block" align="center">La recepción será celebrada en el "Grand Ballroom" del 
      <br/>Antiguo Casino de Puerto Rico al culminar la ceremonia.</Typography>
  <Typography> &nbsp; </Typography>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          marginBottom: '20px', // Add margin to create space at the bottom,
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          [theme1.breakpoints.up('mobile')]: {
            width: 300,
          },
          [theme1.breakpoints.up('bigMobile')]: {
            width: 355,
          },
          [theme1.breakpoints.up('table')]: {
            width: 600,
          },
          [theme1.breakpoints.up('desktop')]: {
            width: 900
          }
        }}
      >
          <Box>
          <ImageListItem
           cols={1}
           rows={1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'cursor',
              '&:hover': {opacity:1}
           }}
           >
          <img src = 'https://gkwedding.blob.core.windows.net/images/AntiguoCasino_Kiara-y-Geraldo-2.jpg' alt="home" loading="lazy" preload="auto"
                  onLoad={handleImageLoad} style={{ opacity: loading ? 0 : 1 }}/>
          {loading && (
                  <div className="spinner-container">
                    <CircleLoader color="#CB8A36" loading size={100}   />
                  </div>
          )}
          </ImageListItem>
        </Box>
      </Box>
      <div>
          <p style={{ fontSize: '12px' }}>
            Arte por{' '}
            <i className="fab fa-instagram"></i> {/* Font Awesome Instagram Icon */}
            <a href="https://instagram.com/liliweds.co?igshid=MzRlODBiNWFlZA==" style={{ textDecoration: 'none' }} target="_blank">liliweds.co</a>.
            <br />
            &copy; Derechos de autor Liliweds 2023 - Todos los derechos reservados.
          </p>
      </div>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>

<Typography display="block" align="center" fontSize={25}>Dirección</Typography>
<Typography display="block" align="center">Antiguo Casino de Puerto Rico</Typography>
<Typography display="block" align="center" component={Link} to="/" color="textPrimary"> <Link href="https://www.google.com/maps/place/Antiguo+Casino+de+Puerto+Rico/@18.4660746,-66.1114321,15z/data=!4m5!3m4!1s0x0:0xb6133829ad269cb6!8m2!3d18.4661034!4d-66.1114214" target="_blank"
  rel="noopener noreferrer" underline="none">
Ave. Ponce de León, Esq. Norzagaray, San Juan, PR 00901
</Link></Typography>

      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>

  </ThemeProvider>  
    </div>
    </Box>
    </ThemeProvider>
    </React.Fragment>
</div>
  );
}