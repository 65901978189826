import React from 'react';
import '../../App.css';
import Faq from "react-faq-component";
import './Faq.css';
import { Typography, CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme2, theme3 } from '../Theme.js';

const data = {
    rows: [
        {
            title: "¿Cuál es la fecha de la boda?",
            content: `viernes, 29 de diciembre del 2023.`,
        },
        
        {
            title: "¿Cuál es la vestimenta?",
            content: `Formal/Etiqueta opcional. `,
        },
        {
            title: "¿Habrá estacionamiento disponible?",
            content: <p>El local cuenta con servicio de valet parking para los invitados, con un costo de $25. Alternativamente, el local está cerca de varios estacionamientos en el Viejo San Juan, siendo el Estacionamiento Doña Fela el más cercano.</p>,
        },
        {
          title: "¿Hasta qué fecha puedo confirmar mi asistencia?",
          content: `Agradecemos que confirme lo antes posible escaneando su QR code, no más tarde del 25 de noviembre del 2023. `,
      },
      {
        title: "¿Dónde puedo encontrar el código para confirmar mi asistencia?",
        content: `Las invitaciones contienen un código único que se encuentra en la hoja de recepción junto a su invitación.`,
    },
    {
          title: "¿Puedo asistir con mis niños?",
          content: <p>Apreciamos mucho a sus niños. Sin embargo, el local solamente permitirá la entrada de niños que sean esenciales para la ceremonia. Por lo tanto, la celebración será solamente para adultos.</p>,
    },
    {
        title: "¿Puedo llevar una pareja?",
        content: `Para mantener nuestra lista de invitados en orden y que todos nuestros invitados tengan un espacio reservado, solamente podrá asistir con la cantidad de personas que indique su invitación.`,
    },
    {
        title: "¿Qué puedo regalarles?",
        content: (
            <>
            El único regalo que pedimos es su presencia en nuestro día especial. Sin embargo, para nuestros seres queridos que han preguntado, agradecemos que su obsequio sea en una aportación monetaria.        </>
          ),
    }
    
    ],
};

const styles = {
    title: 'faq-title',
    bgColor: 'transparent',
    titleTextColor: 'black',
    rowTitleColor: 'black',
    // rowContentColor: 'grey',
  };

const config = {
     animate: true,
     //arrowIcon: "GK",
    tabFocus: true
};

export default function FaqPage() {

  return (
    <React.Fragment>

    <ThemeProvider theme={theme3}>
            <CssBaseline />
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography display="block" align="center" fontSize={50}>Preguntas frecuentes</Typography>
            <Typography> &nbsp; </Typography>
    <div className="main">
      <div className="faq">
        <ThemeProvider theme={theme2}>
            <CssBaseline />
        <Faq
            data={data}
            styles={styles}
            config={config}
        />
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

        </ThemeProvider>
        </div>
    </div>
    </ThemeProvider>
    </React.Fragment>

);
}