import { createTheme } from '@mui/material/styles';
import DiplomaticScript from './diplomatic-script.ttf';
import LattieneSC from './LattieneSC.ttf';

export const theme1 = createTheme({
    breakpoints: {
      values: {
        mobile: 0,
        bigMobile: 350,
        table: 650,
        desktop: 900
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'DroidSerifBold';
          src: url('https://rawgit.com/google/fonts/master/ufl/ubuntu/Ubuntu-Bold.ttf') format('truetype');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'DroidSerif';
          src: url('https://rawgit.com/google/fonts/master/ufl/ubuntumono/UbuntuMono-Italic.ttf') format('truetype');
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: 'Parisienne';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpQyhwD8CtevI.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'Sofia';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_su5uI0Orbjl.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        @font-face {
          font-family: 'Monsieur La Doulaise';
          font-style: normal;
          font-weight: 400;
          src: url(https://fonts.gstatic.com/s/monsieurladoulaise/v15/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZm5ZyEA242Tz.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }

        body {
          margin: 0;
          padding: 0;
        }
      `,
      }
    }
  });

  export const theme2 = createTheme({
    typography: {
      fontFamily: "LattieneSC",
    },
    breakpoints: {
      values: {
        mobile: 0,
        bigMobile: 350,
        table: 650,
        desktop: 900
      }
    },
    // palette: {
    //   primary: { main: "#808080", contrastText: "#fff" },
    //   secondary: { main: "#ffffff", contrastText: "#fff" },
    //   background: {
    //     default: "#e4f0e2"
    //   }
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'LattieneSC';
          font-style: normal;
          font-weight: 400;
          src: url(${LattieneSC}) format('truetype');
        }
        body {
          margin: 0;
          padding: 0;
        }
      `,
      }
    }
  });

  export const theme3 = createTheme({
    typography: {
      fontFamily: "DiplomaticScript",
    },
    breakpoints: {
      values: {
        mobile: 0,
        bigMobile: 350,
        table: 650,
        desktop: 900,
        large: 1168, 
        xl: 1200
      }
    },
    // palette: {
    //   primary: { main: "#808080", contrastText: "#fff" },
    //   secondary: { main: "#ffffff", contrastText: "#fff" },
    //   background: {
    //     default: "#e4f0e2"
    //   }
    // },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'DiplomaticScript';
          font-style: normal;
          font-weight: 400;
          src: url(${DiplomaticScript}) format('truetype');
        }
        body {
          margin: 0;
          padding: 0;
        }
      `,
      }
    }
  });

  export const theme4 = createTheme({
    typography: {
      fontFamily: "LattieneSC",
      fontSize: 20
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'LattieneSC';
          font-style: normal;
          font-weight: 400;
          src: url(${LattieneSC}) format('truetype');
        }
        body {
          margin: 0;
          padding: 0;
        }
      `,
      }
    }
  });

  export const theme5 = createTheme({
    typography: {
      fontFamily: "LattieneSC",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'LattieneSC';
          font-style: normal;
          font-weight: 400;
          src: url(${LattieneSC}) format('truetype');
        }
        body {
          margin: 0;
          padding: 0;
        }
      `,
      }
    }
  });

  // search for https://fonts.googleapis.com/css?family=fontName to get font face.