import React from 'react';
import '../../App.css';
import { Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { theme2, theme3 } from '../Theme.js';

export default function Travel() {

  return (
    <div>
      <React.Fragment>
    <ThemeProvider theme={theme3}>
    <CssBaseline />

    <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>      
        <Typography display="block" align="center" fontSize={50} >
        Viajeros
      </Typography>
      <div>
          <ThemeProvider theme={theme2}>
          <CssBaseline/>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography display="block" align="center" fontSize={25} variant="caption">INFORMACIÓN PARA LOS VIAJEROS</Typography>
          <Typography> &nbsp; </Typography>


      <Typography display="block" align="center" variant="despedidas">AEROPUERTO</Typography>
      <Typography display="block" align="center" variant="despedidas">El aeropuerto más cercano es el Luis Muñoz Marín International Airport</Typography>
      <Typography display="block" align="center" variant="despedidas" component={Link} to="/" color="textPrimary"> <Link href="https://www.google.com/maps/place/Luis+Mu%C3%B1oz+Mar%C3%ADn+International+Airport/@18.4375338,-66.0075531,17z/data=!3m1!4b1!4m5!3m4!1s0x8c0365d810d79007:0x157ef258ddd32b70!8m2!3d18.4375338!4d-66.0053644" target="_blank"
        rel="noopener noreferrer" underline="none">
      SJU - Luis Muñoz Marín International Airport
  </Link></Typography>

      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      
      

      <Typography display="block" align="center" variant="despedidas">HOTEL</Typography>
      <Typography display="block" align="center" variant="despedidas">No tendremos reservación en bloque en ningún hotel.
     </Typography>
      <Typography display="block" align="center" variant="despedidas">
      Recomendamos conseguir hospedaje en cualquier lugar (hotel o AirBnb) en el Viejo San Juan. </Typography>



    <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography display="block" align="center" variant="despedidas">ALQUILER DE CARROS
    </Typography>
      <Typography display="block" align="center" variant="despedidas">Aquí algunas opciones de compañías en Puerto Rico para alquiler de carros.</Typography>
      <Typography display="block" align="center" variant="despedidas" component={Link} to="/" color="textPrimary"> <Link href="https://www.enterprise.com/en/car-rental/locations/us/pr/san-juan-luis-munoz-marin-intl-airport-p130.html" target="_blank"
        rel="noopener noreferrer" underline="none">
      Enterprise
  </Link></Typography>
  <Typography display="block" align="center" variant="despedidas" component={Link} to="/" color="textPrimary"> <Link href="https://www.hertz.com/rentacar/location/puertorico/sanjuan/SJUT01" target="_blank"
        rel="noopener noreferrer" underline="none">
      Hertz
  </Link></Typography>
  <Typography display="block" align="center" variant="despedidas" component={Link} to="/" color="textPrimary"> <Link href="https://www.alamo.com/en/car-rental-locations/us/pr/san-juan-luis-munoz-marin-intl-airport-p1a1.html" target="_blank"
        rel="noopener noreferrer" underline="none">
      Alamo
  </Link></Typography>
  <Typography display="block" align="center" variant="despedidas" component={Link} to="/" color="textPrimary"> <Link href="https://www.avis.com/en/locations/pr/san-juan/sju" target="_blank"
        rel="noopener noreferrer" underline="none">
      Avis
  </Link></Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
</ThemeProvider>
</div>
    </Box>
    </ThemeProvider>
    </React.Fragment>
</div>
  );
}